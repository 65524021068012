<template>
  <page-layout :title="$t('loginSocialView.title')">
    <div class="login-options">
      <div class="login-options__content">
        <div class="login-options__text">
          <p class="login-options__subtitle">
            {{ $t('loginSocialView.create-sentence') }}
          </p>
        </div>
        <div class="login-options__buttons">
          <base-button
            v-for="(button, key) in buttons"
            :key="button.name"
            :data-testid="button.name"
            :is-type="['social']"
            :has-loading="isLoading"
            :customizable="{
              background: button.color,
              labelColor: button.textColor,
              maxWidth: '377px',
            }"
            :class="{
              'login-options__button--hidden': key === 'apple' && !showOptions,
            }"
            @onClick="onClickAction(button)"
          >
            <template #icon>
              <base-icon
                :name="button.icon"
                size="normal"
              />
            </template>
            {{ $t('loginSocialView.socialLoginButtonSignInWith', { name: button.name }) }}
          </base-button>
        </div>
        <div
          class="login-options__toggle"
          :class="{ open: showOptions }"
        >
          <div
            class="login-options__toggle-show"
            data-testid="toggle-options"
            @click="toggleShowOptions"
          >
            {{
              showOptions ? $t('loginSocialView.hide-options') : $t('loginSocialView.show-options')
            }}
            <base-icon name="arrow" />
          </div>
          <div class="login-options__separator">
            <span>
              {{ $t('loginSocialView.or') }}
            </span>
          </div>
          <email-login-component
            :is-loading="isLoading"
            :error-message="errorMessage"
            @sendEmail="sendEmail"
            @showError="showError"
          />
        </div>
      </div>
      <terms-footer-component class="login-options__terms-footer-component" />
    </div>
  </page-layout>
</template>

<script>
import { socialButtons, secondaryButtons } from '@/data/socialButtons.js';
import PageLayout from '@/components/PageLayout/PageLayout.vue';
import TermsFooterComponent from '@/components/TermsFooterComponent/TermsFooterComponent.vue';

export default {
    components: {
        BaseIcon: () =>
            import(/* webpackChunkName: "BaseIcon"*/ '@RepoComponent/BaseIcon/BaseIcon'),
        BaseButton: () =>
            import(/* webpackChunkName: "BaseButton"*/ '@RepoComponent/BaseButton/BaseButton'),
        EmailLoginComponent: () =>
            import(/* webpackChunkName: "BaseButton"*/ '@/components/EmailLoginComponent/EmailLoginComponent'),
        PageLayout,
        TermsFooterComponent,
    },
    props: {
        extraData: {
            type: Object,
            default:() => {},
        },
        isLoading: {
            type: Boolean,
            default:false,
        },
        errorMessage: {
            type: String,
            default: '',
        },
        isChecked: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showOptions: false,
        };
    },
    created() {
        this.buttons = { ...socialButtons, ...secondaryButtons };
    },
    methods: {
        toggleShowOptions() {
            this.showOptions = !this.showOptions;
        },
        onClickAction(button) {
            this.$emit('socialButtonClick', button);
        },
        sendEmail(email) {
            this.$emit('sendEmail', email);
        },
        showError(value) {
            this.$emit('showError', value);
        },
        toggleCheckbox() {
            this.$emit('toggleCheckbox');
        },
    },
};
</script>

<style src="./LoginOptionsComponent.scss" lang="scss" />
