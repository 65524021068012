var render = function render(){var _vm=this,_c=_vm._self._c;return _c('page-layout',{attrs:{"title":_vm.$t('loginSocialView.title')}},[_c('div',{staticClass:"login-options"},[_c('div',{staticClass:"login-options__content"},[_c('div',{staticClass:"login-options__text"},[_c('p',{staticClass:"login-options__subtitle"},[_vm._v(" "+_vm._s(_vm.$t('loginSocialView.create-sentence'))+" ")])]),_c('div',{staticClass:"login-options__buttons"},_vm._l((_vm.buttons),function(button,key){return _c('base-button',{key:button.name,class:{
            'login-options__button--hidden': key === 'apple' && !_vm.showOptions,
          },attrs:{"data-testid":button.name,"is-type":['social'],"has-loading":_vm.isLoading,"customizable":{
            background: button.color,
            labelColor: button.textColor,
            maxWidth: '377px',
          }},on:{"onClick":function($event){return _vm.onClickAction(button)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('base-icon',{attrs:{"name":button.icon,"size":"normal"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('loginSocialView.socialLoginButtonSignInWith', { name: button.name }))+" ")])}),1),_c('div',{staticClass:"login-options__toggle",class:{ open: _vm.showOptions }},[_c('div',{staticClass:"login-options__toggle-show",attrs:{"data-testid":"toggle-options"},on:{"click":_vm.toggleShowOptions}},[_vm._v(" "+_vm._s(_vm.showOptions ? _vm.$t('loginSocialView.hide-options') : _vm.$t('loginSocialView.show-options'))+" "),_c('base-icon',{attrs:{"name":"arrow"}})],1),_c('div',{staticClass:"login-options__separator"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('loginSocialView.or'))+" ")])]),_c('email-login-component',{attrs:{"is-loading":_vm.isLoading,"error-message":_vm.errorMessage},on:{"sendEmail":_vm.sendEmail,"showError":_vm.showError}})],1)]),_c('terms-footer-component',{staticClass:"login-options__terms-footer-component"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }