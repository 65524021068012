import { URL_CONSTANTS } from '@/externalURLs';

const { XSOLLA_API } = URL_CONSTANTS;
const secondSegmentUrl = '/login_redirect?projectId=2c057b65-37c5-11ea-b687-42010aa80004';
const callbackUrl = process.env.VUE_APP_CALLBACK_URL;

export const socialButtons = {
    discord: {
        name: 'Discord',
        icon: 'discord',
        color: '#7289DA',
        url: `${XSOLLA_API}discord${secondSegmentUrl}&login_url=${callbackUrl}`,
    },
    twitch: {
        name: 'Twitch',
        icon: 'twitch',
        color: '#8D45F8',
        url: `${XSOLLA_API}twitch${secondSegmentUrl}&login_url=${callbackUrl}`,
    },
    google: {
        name: 'Google',
        icon: 'google',
        color: '#FFFFFF',
        textColor: '#808080',
        url: `${XSOLLA_API}google${secondSegmentUrl}&login_url=${callbackUrl}`,
    },
};

export const secondaryButtons = {
    apple: {
        name: 'Apple',
        icon: 'apple',
        color: '#fff',
        textColor: '#000',
        url: `${XSOLLA_API}apple${secondSegmentUrl}&login_url=${callbackUrl}`,
    },
};
